import { configureStore } from "@reduxjs/toolkit";
import contacts from "reducers/contacts";
import sipReducer from "reducers/sip";
import xmppReducer from "reducers/xmpp";

export default configureStore({
  reducer: {
    sip: sipReducer,
    xmpp: xmppReducer,
    contacts,
  },
});
