import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  Backspace,
  Mic,
  MicOff,
  Pause,
  PlayArrow,
  Settings,
} from "@material-ui/icons";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { useSipContext } from "../services/SipClient";
import { setPhoneNumber, setCursorPosition, removeDigit } from "../reducers/sip";

const Dialer = () => {
  const dispatch = useDispatch();
  const [dialerFocused, setDialerFocused] = useState(false);
  const {
    sipConnected,
    callActive,
    sipMuted,
    sipOnHold
  } = useSelector((state) => state.sip);
  const {
    phoneNumber,
    placeCall,
    toggleMute,
    toggleHold,
    endCall,
    sendKey,
  } = useSipContext();
  const cursorPosition = useSelector((state) => state.sip.cursorPosition);
  const phoneNumberRef = useRef();

  const setCursorInputPosition = useCallback((position) => {
    phoneNumberRef.current.children[0].children[0].selectionStart = position;
    phoneNumberRef.current.children[0].children[0].selectionEnd = position;
  }, [phoneNumberRef]);

  const onCursorPositionChange = useCallback((event) => {
    dispatch(setCursorPosition(event.target.selectionStart));
  }, [dispatch]);

  const handleNumberClick = useCallback((value) => {
    if (!/^(\d|#|\*)$/.test(value)) {
      console.log("invalid value to send dtmf");
      return;
    }

    // send the value over the SIP connection if it's connected
    if (callActive) {
      sendKey(value);
    }

    dispatch(setPhoneNumber({ value: value, position: cursorPosition }));
  }, [cursorPosition, callActive, dispatch, sendKey]);

  const handleCallClick = useCallback(() => {
    if (callActive) {
      endCall();
      dispatch(setPhoneNumber(""));
    } else {
      placeCall();
    }
  }, [callActive, dispatch, placeCall, endCall]);

  useEffect(() => {
    const listener = e => {
      if (dialerFocused && sipConnected) {
        let movementKeys = ['ArrowLeft', 'ArrowRight', 'Home', 'End'];

        if (!e.getModifierState('NumLock')) {
          movementKeys = [...movementKeys, ...['Numpad4', 'Numpad6']]
        }

        if (phoneNumber !== "" && (e.code === 'Enter' || e.code === 'NumpadEnter')) {
          e.preventDefault();

          handleCallClick();
        } else if (e.code === 'Backspace') {
          dispatch(removeDigit());

          setCursorInputPosition(cursorPosition - 1);
        } else if (movementKeys.includes(e.code)) {
          onCursorPositionChange(e);
        } else {
          handleNumberClick(e.key);

          setCursorInputPosition(cursorPosition + 1);
        }
      }
    };

    document.addEventListener("keyup", listener);

    return () => {
      document.removeEventListener("keyup", listener);
    };
  }, [dialerFocused, cursorPosition, sipConnected, phoneNumber, dispatch, handleCallClick, onCursorPositionChange, handleNumberClick, setCursorInputPosition]);

  return (
    <div className="dialer">
      <audio id="sip-audio" controls>
        <p>Your browser doesn't support HTML5 audio.</p>
      </audio>

      <div className="settings">
        <h3>Place A Call</h3>
        <Button>
          <Settings />
        </Button>
      </div>

      {!callActive && (
        <>
          <div tabIndex={0}> {/* Here because onBlur never fires after a call is made without it - DON'T TOUCH */}
            <TextField
              id="number-input"
              className="number-input"
              value={phoneNumber}
              onFocus={() => setDialerFocused(true)}
              onBlur={() => setDialerFocused(false)}
              onMouseUp={(event) => onCursorPositionChange(event)}
              ref={phoneNumberRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => dispatch(removeDigit())}
                    position="end"
                  >
                    <IconButton size="small">
                      <Backspace fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="1-555-23... / John Doe"
            />
          </div>
          <div className="digits">
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("1")}
            >
              1 <span className="letters">&nbsp;</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("2")}
            >
              2 <span className="letters">A B C</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("3")}
            >
              3 <span className="letters">D E F</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("4")}
            >
              4 <span className="letters">G H I</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("5")}
            >
              5 <span className="letters">J K L</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("6")}
            >
              6 <span className="letters">M N O</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("7")}
            >
              7 <span className="letters">P Q R S</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("8")}
            >
              8 <span className="letters">T U V</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("9")}
            >
              9 <span className="letters">W X Y Z</span>
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("*")}
            >
              *
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("0")}
            >
              0
            </Button>
            <Button
              variant="outlined"
              className="digit"
              onClick={() => handleNumberClick("#")}
            >
              #
            </Button>
          </div>
        </>
      )}

      {callActive && (
        <div className="call-active-display">
          <div className="avatar">#</div>
          <div className="number">{phoneNumber}</div>
          <div className="call-active-btns">
            <div
              className="call-ctl-btn"
              title={`${sipMuted ? "Unmute Microphone" : "Mute Microphone"}`}
              onClick={() => toggleMute()}
            >
              {!sipMuted && (
                <>
                  <MicOff />
                  <div>Mute</div>
                </>
              )}
              {sipMuted && (
                <>
                  <Mic />
                  <div>Unmute</div>
                </>
              )}
            </div>
            <div
              className="call-ctl-btn"
              title={`${sipOnHold ? "Remove Hold" : "Place on Hold"}`}
              onClick={() => toggleHold()}
            >
              {!sipOnHold && (
                <>
                  <Pause />
                  <div>Hold</div>
                </>
              )}
              {sipOnHold && (
                <>
                  <PlayArrow />
                  <div>Resume</div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="call-controls">
        <Button
          fullWidth
          variant="outlined"
          title={`${callActive ? "End Call" : "Place Call"}`}
          disabled={!sipConnected || phoneNumber === ""}
          onClick={() => handleCallClick()}
        >
          {!callActive && "Place Call"}
          {callActive && "End Call"}
        </Button>
      </div>
    </div>
  );
};

export default Dialer;
