import { createContext, useContext } from "react";
import { client, xml } from "@xmpp/client";
import { useDispatch } from "react-redux";
import setupRoster from "@xmpp-plugins/roster";
import { DefaultXmppCredentials, useAuthContext } from "./auth";
import {
  xmppConnected,
  xmppDisconnected,
  rosterUpdated,
  messageReceived,
  messageSent,
  userComposingMessage,
  updateContactStatus,
  updateUserStatus,
} from "reducers/xmpp";
import short from "short-uuid";
import {DateTime} from 'luxon';
import { ParseMessage } from "reducers/messageHistory";

export const DOMAIN = "xmpp.pbxapi.com";

// Set well into the past before we brought the system online
// so we can get a full message history.  2010-01-01T00:00:00Z.
const EARLIEST_MESSAGE_DATE = 1262304000000;

export const STATUS_OFFLINE = "offline.png";

export const STATUS_ONLINE = "online.png";

export const STATUS_BUSY = "busy.png";

export const STATUS_AWAY = "away.png";

const CHAT_POLL_CEILING = 10000;

const CHAT_POLL_JITTER = 1000;

const HTTPS_PORT = "4443";

const WSS_PORT = "7443"

const XmppContext = createContext();

export const useXmppContext = () => {
  const context = useContext(XmppContext);

  if (context === undefined) {
    throw new Error("useXmppClient must be used within an XmppClientProvider");
  }

  return context;
};

export const XmppClientProvider = ({ children }) => {
  let xmpp;
  let xmppClient;
  let roster;
  let userAddress;

  const { xmppCredentials } = useAuthContext();
  const dispatch = useDispatch();

  const sendMessage = async (to, text) => {
    if (!xmpp) {
      console.error("no xmpp client connected");
      return;
    }

    const message = xml(
      "message",
      { type: "chat", from: userAddress, to: to },
      xml("body", {}, text)
    );

    const msg = ParseMessage(message);
    msg.outgoing = true;
    dispatch(messageSent(msg));

    await xmpp.send(message);
  };

  const fetchMessageHistory = async (jid, start = null) => {
    if (!xmpp) {
      return;
    }

    const { iqCaller } = xmpp;

    // NOTE: the offical documented way of querying the message archives DOES NOT WORK with
    // OpenFire.  A custom stanza that OpenFire understands needs to be constructed which
    // is what the code below does.  See https://stackoverflow.com/q/51299599
    const request = xml(
      "iq",
      { type: "set", id: jid},
      xml(
        "query",
        { xmlns: "urn:xmpp:mam:0", queryid: jid},
        xml(
          "x",
          { xmlns: "jabber:x:data", type: "submit"},
          xml(
            "field",
            { var: "with"},
            xml("value", null, jid)
          ),
          xml(
            "field",
            { var: "start"},
            xml("value", null, DateTime.fromMillis(start ? start : EARLIEST_MESSAGE_DATE).toUTC().toFormat("yyyy-MM-dd'T'hh:mm:ss'Z'"))
          ),
          xml(
            "field",
            { var: "FORM_TYPE", type: "hidden"},
            xml("value", null, "urn:xmpp:mam:0")
          )
        )
      )
    );

    try {
      await iqCaller.request(request);

      let messageHistory = localStorage.getItem("messageHistory");

      if (messageHistory === null) {
        messageHistory = {};
      } else {
        messageHistory = JSON.parse(messageHistory);
      }

      if (!messageHistory[jid]) {
        messageHistory[jid] = DateTime.fromMillis(EARLIEST_MESSAGE_DATE).toSeconds();

        localStorage.setItem("messageHistory", JSON.stringify(messageHistory));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const setStatus = async (status) => {
    try {        
      await xmpp.send(xml("presence", null, xml("status", null, status)));

      dispatch(updateUserStatus({status: getUserStatusIcon(status)}));
    } catch (error) {
      console.error(error.message);
    }
  }

  const fetchUserStatus = async (jid, timeoutCallback) => {
    let response = await fetch(`https://${DOMAIN}:${HTTPS_PORT}/plugins/presence/status?jid=${encodeURIComponent(jid)}&req_jid=${xmppCredentials.username}@${DOMAIN}&type=text`);
    let status = STATUS_OFFLINE;
    const delay= CHAT_POLL_CEILING - (Math.floor(Math.random() * CHAT_POLL_JITTER) + 1);

    if (response.status === 200) {
      const statusText = await response.text();

      console.debug(`Status for ${jid} is ${statusText}`);
      
      status = getUserStatusIcon(statusText);
    }

    return {
      jid: jid, 
      status: status, 
      timeout: setTimeout(async () => {
        const userStatus = await fetchUserStatus(jid, timeoutCallback);

        dispatch(timeoutCallback(userStatus));
      }, delay),
    };
  }

  const getUserStatusIcon = (statusText) => {
    let status = STATUS_OFFLINE;
       
    switch (statusText.toLowerCase().trim()) {
      case "available":
      case "online":
        status = STATUS_ONLINE;
        break;
      case "busy":
        status = STATUS_BUSY;
        break;
      case "away":
        status = STATUS_AWAY;
        break;
      default:
        // Do nothing, default is offline
    }
  
    return status;
  }

  const syncRoster = async () => {
    const fullRoster = await roster.get();

    console.log("xmpp-client roster sync", fullRoster);
    
    if (!fullRoster) {
      return;
    }

    const list = await Promise.all(fullRoster.items.map(async (item) => {
      const jid = item.jid.toString();
      const userStatus = await fetchUserStatus(jid, updateContactStatus);
      
      await fetchMessageHistory(jid);

      return {
        name: item.name,
        jid: jid,
        groups: item.groups,
        ...userStatus
      };
    }));

    dispatch(rosterUpdated(list));
  };

  const stopXmppClient = async () => {
    if (!xmpp) {
      console.error("no xmpp client connected");
      return;
    }

    try {
      await xmpp.stop();
    } catch (error) {
      console.error(error.message);
    }
  }

  if (!xmpp && xmppCredentials !== DefaultXmppCredentials) {
    console.log("xmpp credentials:", xmppCredentials);
    const config = {
      service: `wss://${DOMAIN}:${WSS_PORT}/ws`,
      domain: DOMAIN,
      resource: `ucaas-${short.generate()}`,
      username: encodeURIComponent(xmppCredentials.username),
      password: xmppCredentials.password,
    };
    xmpp = client(config);
    roster = setupRoster(xmpp);

    roster.on("set", async ({ item, version }) => {
      console.log(`xmpp-client roster update ${version}`, item);
      await syncRoster();
    });

    xmpp.on("status", (status) => {
      console.debug("xmpp-client status", status);
    });

    xmpp.on("error", (err) => {
      console.error("xmpp-client error", err);
    });

    xmpp.on("online", async (address) => {
      console.log("xmpp-client online", address);
      
      try {        
        await setStatus("available");

        fetchUserStatus(`${address._local}@${address._domain}`, updateUserStatus);

        dispatch(xmppConnected(address.toString()));
        
        userAddress = address.toString();
      } catch (error) {
        console.error(error.message);
      }
    });

    xmpp.on("offline", () => {
      console.log("xmpp-client offline");
      dispatch(xmppDisconnected());
    });

    xmpp.on("stanza", async (stanza) => {
      console.log(`xmpp-client stanza[${stanza.name}]`, stanza);

      if (stanza.is("message")) {
        if (stanza.getChild("composing")) {
          dispatch(userComposingMessage(stanza.attrs.from));
          return;
        } else if (stanza.getChild("fin")) {
          // This indicates that server is done sending data for a request and should
          // not be processed as a message between two users
          return;
        }

        const msg = ParseMessage(stanza.clone());

        dispatch(messageReceived(msg));
      }

      if (stanza.is("presence")) {
        // @TODO the user should be asked if they want to accept this
        if (stanza.attrs.type === "subscribe") {
          console.log("xmpp-subscribe response");
          const msg = stanza.clone();

          msg.attrs.to = stanza.attrs.from;
          msg.attrs.from = stanza.attrs.to;
          msg.attrs.type = "subscribed";

          await xmpp.send(msg);
        }
      }
    });

    const connectXmpp = async () => {
      console.log("xmpp connect method");
  
      try {
        await xmpp.start();
        await syncRoster();
  
        window.addEventListener("beforeunload", async (e) => {
          e.preventDefault();
  
          await xmpp.send(xml("presence", { type: "unavailable" }));
          await xmpp.stop();
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    connectXmpp();
  }

  // return async () => {
  //   console.log("xmpp-client disconnecting");
  //   await xmpp.send(xml("presence", { type: "unavailable" }));
  //   await xmpp.stop();
  // };

  xmppClient = {
    client: xmpp,
    sendMessage,
    fetchMessageHistory,
    setStatus,
    getUserStatusIcon,
    stopXmppClient,
  };

  return (
    <XmppContext.Provider value={xmppClient}>{children}</XmppContext.Provider>
  );
};
