import React from "react";
import Background from 'assets/technologists.jpg';
import Login from "./Login";
import Logo from "assets/newt-cloud-logo-knockout.svg";
import { Button } from "@material-ui/core";

export default function Home() {
  return (
    <div className="home-container">
      <img className="background-img" src={Background} alt=""/>
      <div className="home-content">
        <div className="home-pitch">
          <div className="logo">
            <img src={Logo} alt="Newt Cloud logo" />
          </div>
          <h1 className="title">Communication as a Service</h1>
          <p>
  I'm baby tacos jean shorts VHS bitters raclette. Vexillologist edison bulb venmo kickstarter wayfarers truffaut man braid enamel pin aesthetic viral 3 wolf moon beard. Lomo raw denim taiyaki man braid portland hoodie. Selvage biodiesel prism, drinking vinegar tilde bespoke godard squid. Put a bird on it glossier plaid, banjo flannel pickled flexitarian. Letterpress venmo gochujang, bespoke ugh gentrify fingerstache.
          </p>
          <div className="button-container">
            <Button variant="contained" className="learn-more-button">Learn More</Button>
          </div>
        </div>
        <Login />
      </div>
    </div>
  );
}
