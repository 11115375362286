import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Redirect } from "react-router";
import {Link} from 'react-router-dom';
import { DefaultLoginError, useAuthContext } from "services/auth";

const Login = () => {
  const { logUserIn, isLoggedIn, loginError } = useAuthContext();
  const [loginData, setLoginData] = useState({});

  const handleChange = (e) => {
    setLoginData((state) => {
      return { ...state, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await logUserIn(
      loginData.username,
      loginData.password,
      loginData.account_name
    );
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="login-container">
      <h1>Get Started</h1>
      <p>Don't have an account? <Link to="/sign-up">Sign Up Now</Link></p>
      <form className="login-form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          id="username"
          name="username"
          label="Username"
          onChange={handleChange}
        />
        <TextField
          id="password"
          fullWidth
          name="password"
          type="password"
          label="Password"
          onChange={handleChange}
        />
        <TextField
          id="account_name"
          fullWidth
          name="account_name"
          label="Account Name"
          onChange={handleChange}
        />
        {loginError !== DefaultLoginError && <p style={{color: "red"}}>{loginError.message}</p>}
        <div className="submit">
          <Button type="submit" fullWidth variant="contained">
            Log in
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
