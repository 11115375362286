import { createSlice } from "@reduxjs/toolkit";

export const contacts = createSlice({
    name: "contacts",
    initialState: {
      contacts: [    
        {
          id: 1,
          name: "Jody Schnarr",
          primaryPhone: "extension",
          phones: [{ name: "extension", number: "7100" }],
        },
        {
          id: 2,
          name: "Aaron Shames",
          primaryPhone: "extension",
          phones: [{ name: "extension", number: "7100" }],
        },
        {
          id: 3,
          name: "Phil Guinchard",
          primaryPhone: "extension",
          phones: [{ name: "extension", number: "7021" }],
        },
      ],
    },
    reducers: {
      setContacts: (state, action) => {
        state.contacts = action.payload;
      },
    },
  });
  
  export const {
    setContacts
  } = contacts.actions;
  
  export default contacts.reducer;