import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "react-use";
import { Link } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { DateTime } from "luxon";
import { currentMessageTargetUpdated } from "reducers/xmpp";

export default function TeamList() {
  const { rosterName, contacts, messages, currentMessageTarget } = useSelector(
    (state) => state.xmpp
  );

  const dispatch = useDispatch();

  const updateMessageHistory = (jid) => {
    let messageHistory = localStorage.getItem("messageHistory");

    messageHistory = messageHistory === null ? {} : JSON.parse(messageHistory);

    messageHistory[jid] = DateTime.now().toSeconds();

    localStorage.setItem("messageHistory", JSON.stringify(messageHistory));
  }

  const handleClickContact = (contact) => {
    if (currentMessageTarget && currentMessageTarget.jid === contact.jid) {
      return;
    }

    if (currentMessageTarget && currentMessageTarget.jid !== contact.jid) {
      updateMessageHistory(currentMessageTarget.jid);
    }

    dispatch(currentMessageTargetUpdated(contact));

    updateMessageHistory(contact.jid);
  };

  const renderName = (contact) => {
    if ((currentMessageTarget && contact.jid === currentMessageTarget.jid) || !messages[contact.jid]) {
      return <div>{contact.name}</div>;
    }

    let messageHistory = localStorage.getItem("messageHistory");
    let newMessages = messages && messages[contact.jid] ? messages[contact.jid].length : [];
    
    messageHistory = messageHistory === null ? {} : JSON.parse(messageHistory);
 
    if (messageHistory !== undefined && messageHistory[contact.jid]) {
      newMessages = messages[contact.jid].filter((m) => {
        return DateTime.fromISO(m.timestamp).toSeconds() > messageHistory[contact.jid] && contact.jid === m.from;
      });
    }

    if (newMessages === 0) {
      return <div>{contact.name}</div>;
    }

    return (
      <Badge badgeContent={newMessages.length} color="error">
        <div>{contact.name}</div>
      </Badge>
    );
  }

  return (
    <>
      <span className="section-header">Team Channels</span>
      <div className="link-group team-chat-roster">
        <Link to="#" className="nav-item channel">
          {rosterName}
        </Link>
        {contacts.map((contact, index) => {
          return (
            <div key={`contact-${index}`}>
              <div
                className={`contact${
                  currentMessageTarget && contact.jid === currentMessageTarget.jid
                    ? " active"
                    : ""
                }`}
                style={{display: 'flex', flexDirection: 'row'}}
                onClick={() => handleClickContact(contact)}
                key={contact.name}
              >
                <div><img src={`/chat_statuses/${contact.status}`} alt="" width="10px" border="0" /></div>&nbsp;
                {renderName(contact)}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
