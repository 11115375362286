import { createContext, useContext, useState } from "react";
import { useLocalStorage } from "react-use";

const AuthContext = createContext(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("AuthContext cannot be used outside of an auth provider");
  }

  return context;
};

export const AuthProvider = ({ children }) => {
  const value = useAuth();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const DefaultLoginError = null;
export const DefaultLoggedInState = false;
export const DefaultUsernameState = null;
export const DefaultXmppCredentials = null;
export const DefaultSipCredentials = null;

const useAuth = () => {
  const [isLoggedIn, setLoggedIn] = useLocalStorage(
    "isLoggedIn",
    DefaultLoggedInState
  );

  const [username, setUsername] = useLocalStorage(
    "username",
    DefaultUsernameState
  );

  const [loginError, setLoginError] = useState(DefaultLoginError);

  const [xmppCredentials, setXmppCredentials] = useLocalStorage(
    "xmppCredentials",
    DefaultXmppCredentials
  );

  const [sipCredentials, setSipCredentials] = useLocalStorage(
    "sipCredentials",
    DefaultSipCredentials
  );

  const logUserOut = () => {
    setSipCredentials(DefaultSipCredentials);
    setXmppCredentials(DefaultXmppCredentials);
    setLoggedIn(false);
  };

  const logUserIn = async (username, password, accountName) => {
    setLoginError(DefaultLoginError);

    try {
      const response = await fetch("https://webrtc.coreapi.co/login", {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
          account: accountName,
        }),
      });

      const body = await response.json();

      if (response.status === 401) {
        setLoginError({ message: "Invalid login credentials" });
        return;
      } else {
        setLoginError({ message: "Login failure" });
      }

      // process the body and set the credentials

      const data = body.data;
      let sipCredentials = {...data.sip};

      sipCredentials.accountName = accountName;

      setSipCredentials(sipCredentials);
      setXmppCredentials(data.xmpp);
      setLoggedIn(true);
      setUsername(username);

      // TODO: Fix audio element race conditions.  Leave this alone for now. 
      window.location.reload();
    } catch (err) {
      setLoggedIn(false);
      console.error("authentication error:", err);
    }
  };

  return {
    sipCredentials,
    xmppCredentials,
    isLoggedIn,
    username,
    loginError,
    logUserIn,
    logUserOut,
  };
};
