import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  TextField,
} from "@material-ui/core";
import {
  Assistant,
  ChatBubble,
  DialerSip,
  Email,
  Send,
  Phone,
} from "@material-ui/icons";
import {setPhoneNumber} from "reducers/sip";
import { useSipContext } from "services/SipClient";

const ChatInput = ({ sendMessage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [chatInput, setChatInput] = useState("");
  const {currentMessageTarget, contacts} = useSelector((state) => {
    return {
      currentMessageTarget: state.xmpp.currentMessageTarget,
      contacts: state.contacts.contacts,
    };
  });
  const {callActive} = useSelector((state) => state.xmpp);
  const {placeCall} = useSipContext();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const callUser = () => {
    const contact = contacts.find((c) => c.name === currentMessageTarget.name);

    if (contact === undefined) {
      alert(`No matching contacts were found for the user "${currentMessageTarget.name}"`);
    } else {
      const phoneNumber = contact.phones.find((p) => p.name === contact.primaryPhone);

      if (phoneNumber === undefined) {
        alert(`Could not find a valid phone number for ${currentMessageTarget.name}`);
      } else {
        dispatch(setPhoneNumber(phoneNumber.number));

        placeCall();
      }
    }
  }

  const renderCallButton = () => {
    if (currentMessageTarget === null || callActive) {
      return null;
    }
  
    return (
      <InputAdornment onClick={callUser}>
        <Button startIcon={<Phone />}></Button>
      </InputAdornment>
    );
  }

  const id = anchorEl ? "shortcuts-popover" : undefined;

  const handleChatInput = (input) => {
    setChatInput(input);
  };

  const sendChatMessage = async () => {
    try {
      await sendMessage(currentMessageTarget.jid, chatInput);
      
      setChatInput("");
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleKeyPress = (e) => {
    if ((e.code === 'Enter' || e.code === 'NumpadEnter') && chatInput.length > 0) {
      sendChatMessage();
    }
  };

  return (
    <div className="chat-box-container">
      <div className="chat-box">
        <TextField
          value={chatInput}
          onChange={(e) => handleChatInput(e.currentTarget.value)}
          onKeyDown={handleKeyPress}
          variant="outlined"
          fullWidth
          multiline
          InputProps={{
            startAdornment: (
              <InputAdornment onClick={handleClick} position="start">
                <Button startIcon={<Assistant />}></Button>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment onClick={sendChatMessage} position="end">
                <IconButton>
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Popover
          id={id}
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            <ListSubheader>Suggested Shortcuts</ListSubheader>
            <ListItem
              button
              onClick={() => {
                setChatInput("/email ");
                handleClose();
              }}
            >
              <ListItemIcon>
                <Email />
              </ListItemIcon>
              <ListItemText>
                Write an email <code>/email @contact</code>
              </ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setChatInput("/chat ");
                handleClose();
              }}
            >
              <ListItemIcon>
                <ChatBubble />
              </ListItemIcon>
              <ListItemText>
                Send a chat message <code>/chat @contact</code>
              </ListItemText>
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setChatInput("/call ");
                handleClose();
              }}
            >
              <ListItemIcon>
                <DialerSip />
              </ListItemIcon>
              <ListItemText>
                Place a call <code>/call @contact</code>
              </ListItemText>
            </ListItem>
          </List>
        </Popover>
      </div>
      <div style={{flex: 1}}>
        {renderCallButton()}
      </div>
    </div>
  );
};

export default ChatInput;
