import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "services/ProtectedRoute";
import Dashboard from "components/Dashboard";
import Home from "components/Home";
import SipClientProvider from "services/SipClient";
import { XmppClientProvider } from "services/XmppClient";
import { AuthProvider } from "services/auth";
import MusicPlayerProvider from "./services/MusicPlayer";

function App() {
  return (
    <AuthProvider>
      <XmppClientProvider>
        <SipClientProvider>
          <MusicPlayerProvider>
            <CssBaseline>
              <Router>
                <div className="app-container">
                  <Switch>
                    <ProtectedRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <Route path="/" component={Home} />
                  </Switch>
                </div>
              </Router>
            </CssBaseline>
          </MusicPlayerProvider>
        </SipClientProvider>
      </XmppClientProvider>
    </AuthProvider>
  );
}

export default App;
