import { createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { Web } from "sip.js";
import { DefaultSipCredentials, useAuthContext } from "services/auth";
import {
  setPhoneNumber,
  callConnecting,
  callConnected,
  callDisconnected,
  sipConnected,
  sipRegistered,
  sipDisconnected,
  answered,
  hold,
  resume,
  mute,
  unMute,
} from "reducers/sip";

export const TYPE_CALL_SENT = 0;

export const TYPE_CALL_RECEIVED = 1;

const DEFAULT_CALLER = 'Unknown';

const SipContext = createContext(null);

export const useSipContext = () => {
  const ctx = useContext(SipContext);

  if (!ctx) {
    throw new Error("SipContext must be used within a SipContext.Provider");
  }

  return ctx;
};

const getAudioElement = () => {
  let audio = document.getElementById("sip-audio");

  // If we can't find any audio element we create a temp one.  Once the DOM loads the audio element will be rendered
  // and the temporairy one will not be necessary.  This is here because on first load the SIP client needs an audio 
  // element but teh client is loaded before the DOM initially.
  if (audio === null) {
    audio = document.createElement("audio");
    audio.id = "sip-sudio";
    audio.controls = true;
  }

  if (!(audio instanceof HTMLAudioElement)) {
    throw new Error('Element "sip-audio" not found or not an audio element.');
  }

  return audio;
}

let sipClient = null;

let sipConnection = null;

const SipClientProvider = ({ children }) => {
  const { phoneNumber, registered, callActive, callStart } = useSelector((state) => state.sip);
  const { username, sipCredentials } = useAuthContext();
  const dispatch = useDispatch();

  const getCallRecord = (type, callerName = DEFAULT_CALLER) => {
    return {
      username: username,
      callRecord: {
        name: callerName,
        number: sipClient.phoneNumber,
        length: "00:00:00",
        time: DateTime.now().toMillis(),
        type: type,
      }
    }
  }

  const toggleHold = async () => {
    if (!sipConnection) {
      return;
    }

    try { 
      if (!sipConnection.isHeld()) {
        console.debug("action-log: hold call requested");
        await sipConnection.hold();
        return;
      }
  
      console.debug("action-log: resume call requested");
      await sipConnection.unhold();
      dispatch(resume());
    } catch (error) {
      console.error(error.message);
    }
  };

  const toggleMute = () => {
    if (!sipConnection) {
      return;
    }

    if (sipConnection.isMuted()) {
      console.debug("action-log: unmute call");
      sipConnection.unmute();
      dispatch(unMute());
      return;
    }

    console.debug("action-log: mute call");
    sipConnection.mute();
    dispatch(mute());
  };

  const endCall = async () => {
    console.debug("action-log: hangup " + sipConnection);

    if (!sipConnection) {
      return;
    }

    try {
      await sipConnection.hangup();
    } catch (error) {
      console.error(error.message);
    } finally {
      logCall();

      dispatch(setPhoneNumber(""));
    }
  };

  const logCall = () => {
    if (callStart === null) {
      dispatch(callDisconnected());
    } else {
      dispatch(callDisconnected({
        username: username,
        number: sipClient.phoneNumber,
      }));
    }
  }

  const sendKey = async (value) => {
    console.debug(`action-log: send-key["${value}"]`);
    if (!sipConnection) {
      return;
    }

    try {
      await sipConnection.sendDTMF(value);
    } catch (error) {
      console.error(error.message);
    }
  };

  const placeCall = async () => {
    console.debug(`action-log: initiated-call["${sipClient.phoneNumber}"]`);
    
    if (!sipConnection || sipClient.phoneNumber.trim() === '') {
      return;
    }

    try {
      await sipConnection.call(
        `sip:${sipClient.phoneNumber}@${sipCredentials.accountName}.sip.newtws.com`
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  // @TODO allow the user to change input devices
  // console.log(
  //   navigator.mediaDevices.enumerateDevices().then((devices) => {
  //     console.log(devices);
  //   })
  // );

  // If there's no sip Connection and the credentials are valid
  if (!registered && !sipConnection && sipCredentials !== DefaultSipCredentials) {
    const server = "wss://api.phonecentral.co:5050";
    const aor = `sip:${sipCredentials.username}@${sipCredentials.realm}`;

    const options = {
      aor,
      delegate: {
        onCallReceived: async () => {
          console.log("call-log: received");
          dispatch(callConnecting());

          try {
            await sipConnection.answer();
          } catch (error) {
            console.error(error.message);
          }
        },
        onCallCreated: () => {
          console.log("call-log: created");
          
          dispatch(callConnected(getCallRecord(TYPE_CALL_SENT)));
        },
        onCallAnswered: () => {
          console.log("call-log: answered");

          dispatch(answered());
        },
        onCallHangup: () => {
          console.log("call-log: hangup");
          
          logCall();
        },
        onCallHold: () => {
          console.log("call-log: hold");
          dispatch(hold());
        },
        onServerConnect: () => {
          console.log("call-log: server connected");

          dispatch(sipConnected());

          window.addEventListener("beforeunload", (e) => {
            e.preventDefault();

            if (!callActive) {
              return;
            }
            // @TODO Check to see if there's an active call

            e.returnValue =
              "You still have a call active. Are you sure you want to leave the page?";

            return e.returnValue;
          });
        },
        onRegister: () => {
          console.log("call-log: registering SIP client");

          dispatch(sipRegistered(true));
        },
        onServerDisconnect: () => {
          console.log("call-log: server disconnect");

          dispatch(sipDisconnected());
          dispatch(sipRegistered(false));
        },
      },
      media: {
        remote: {
          audio: getAudioElement(),
        },
      },
      userAgentOptions: {
        level: 'warn',
        authorizationUsername: sipCredentials.username,
        authorizationPassword: sipCredentials.password,
      },
    };

    sipConnection = new Web.SimpleUser(server, options);

    const connectSimpleUser = async () => {
      try {
        await sipConnection.connect();
        await sipConnection.register();
      } catch (error) {
        console.error(error.message);
      }
    };

    connectSimpleUser();
  }

  if (sipClient === null) {
    sipClient = {
      client: sipConnection,
      phoneNumber,
      placeCall,
      endCall,
      toggleHold,
      toggleMute,
      sendKey,
    };
  } else {
    sipClient.phoneNumber = phoneNumber;
  }

  return (
    <SipContext.Provider value={sipClient}>{children}</SipContext.Provider>
  );
};

export default SipClientProvider;
