import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputAdornment, TextField } from "@material-ui/core";
import { Search, Phone } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { setPhoneNumber } from "../reducers/sip";

const ContactList = () => {
  const dispatch = useDispatch();

  const contactMasterList = useSelector((state) => state.contacts.contacts);

  const [contacts, setContacts] = useState(contactMasterList);

  const filterContacts = (term = null) => {
    let filtered = contactMasterList.slice();

    if (typeof term === 'string' && term.trim() !== '') {
      const regEx = new RegExp(`.*${term}.*`, "i");

      filtered = filtered.filter((contact) => regEx.test(contact.name));
    }

    setContacts(filtered);
  }

  const populateDialer = (contact) => {
    const phone = contact.phones.find((phone) => phone.name === contact.primaryPhone);
    
    if (phone !== undefined) {
      dispatch(setPhoneNumber(phone.number));
    }
  }

  return (
    <div className="contact-list">
      <span className="section-header">Contacts</span>

      <TextField
        className="search"
        name="search-contacts"
        placeholder="Search Contacts"
        onChange={(event) => filterContacts(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <div className="link-group">
        {contacts.map((contact) => (
          <Link to="#" key={contact.name} onClick={() => populateDialer(contact)} className="nav-item contact">
            <div className="name">{contact.name}</div>
            <div className="phone">
              <Phone />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ContactList;
