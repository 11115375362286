import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Menu, MenuItem } from "@material-ui/core";
import { useAuthContext } from "services/auth";
import { STATUS_OFFLINE, STATUS_ONLINE, STATUS_BUSY, STATUS_AWAY, useXmppContext } from "services/XmppClient";

const STATUSES = [
  STATUS_ONLINE,
  STATUS_BUSY,
  STATUS_AWAY,
  STATUS_OFFLINE,
]

const renderStatusMenuItem = (status, setStatus, setAnchorEl) => {
  const onStatusUpdateClick = (status) => {
    setStatus(status);

    setAnchorEl(null);
  }

  const textStatus = status.slice(0, -4);

  return (
    <MenuItem key={`status-${textStatus}`} onClick={() => onStatusUpdateClick(textStatus)}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div>
          <img src={`/chat_statuses/${status}`} alt="" width="10px" border="0" />
        </div>
        <div style={{textTransform: "capitalize"}}>
          &nbsp;{textStatus}
        </div>
      </div>
    </MenuItem>
  );
}

const ProfileNav = () => {
  const {logUserOut, xmppCredentials} = useAuthContext();
  const {setStatus, stopXmppClient} = useXmppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const { userStatus } = useSelector(
    (state) => state.xmpp
  );

  const logout = () => {
    logUserOut();

    stopXmppClient().catch((error) => {
      console.error(error.message);
    });
  }

  return (
    <div className="profile-nav">
      <button className="profile-button"
      onClick={(e) => setAnchorEl(e.currentTarget)}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>
            <img src={`/chat_statuses/${userStatus}`} alt="" width="10px" border="0" />
          </div>
          <div>
            &nbsp;{xmppCredentials.name}
          </div>
        </div>
        <Avatar
          variant="circular"
          alt="Loggedin User"
          src="https://source.unsplash.com/random/200x200"
          className="profile-avatar"
        />
      </button>
      <Menu id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
      {STATUSES.map((status) => renderStatusMenuItem(status, setStatus, setAnchorEl))}
      <MenuItem>Profile</MenuItem>
      <MenuItem onClick={() => logout()}>Sign Out</MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileNav;
