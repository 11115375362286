import React from "react";
import { NavLink } from "react-router-dom";
import ContactList from "components/ContactList";
import Dialer from "components/Dialer";
import Chat from "components/Chat";
import ProfileNav from "components/ProfileNav";
import TeamList from "components/TeamList";
import CallPresence from "components/CallPresence";
import CallLog from "components/CallLog";
import Logo from "assets/newt-cloud-logo-knockout.svg";
import { NotificationsActive } from "@material-ui/icons";

export default function Dashboard() {
  return (
    <div className="dashboard-container">
      <CallPresence />
      <div className="header">
        <div className="logo">
          <img src={Logo} alt="Newt Cloud logo" />
        </div>
        <div className="user">
          <ProfileNav />
        </div>
      </div>

      <div className="left-sidebar">
        <div className="nav">
          <NavLink to="/dashboard" className="nav-item">
            <NotificationsActive /> Bullpen
          </NavLink>

          <TeamList />
        </div>

        <ContactList />
      </div>
      <div className="main-content">
        <Chat />
      </div>
      <div className="right-sidebar">
        <Dialer />
        <CallLog />
      </div>
    </div>
  );
}
