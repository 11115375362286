import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

const CALL_HISTORY_KEY = 'callHistory';

const initCallHistory = () => {
  const callHistory = localStorage.getItem(CALL_HISTORY_KEY);

  return callHistory === null ? {} : JSON.parse(callHistory);
}

const getCallDuration = (callStart) => {
  let duration = DateTime.now().toMillis() - callStart;

  return [
    Math.floor((duration  / 1000 / 3600 ) % 24).toString().padStart(2, '0'),
    Math.floor((duration / 1000 / 60) % 60).toString().padStart(2, '0'),
    Math.floor((duration / 1000) % 60).toString().padStart(2, '0'),
  ].join(':');
}

export const sipSlice = createSlice({
  name: "sipClient",
  initialState: {
    phoneNumber: "",
    cursorPosition: 0,
    sipConnected: false,
    registered: false,
    callConnecting: false,
    callActive: false,
    callStart: null,
    sipOnHold: false,
    sipMuted: false,
    callHistory: initCallHistory(),
  },
  reducers: {
    setPhoneNumber: (state, action) => {
      if (action.payload.hasOwnProperty("position")) {
        let phoneNumber = state.phoneNumber;
        const value = action.payload.value;
        const position = action.payload.position;

        state.phoneNumber = [phoneNumber.slice(0, position), value, phoneNumber.slice(position)].join("");
        state.cursorPosition = position + 1;
      } else {
        state.phoneNumber = action.payload;
        state.cursorPosition = action.payload.length;
      }
    },
    setCursorPosition: (state, action) => {
      state.cursorPosition = action.payload;
    },
    removeDigit: (state) => {
      if (state.phoneNumber.length >= 1) {
        state.phoneNumber = state.phoneNumber.slice(0, state.cursorPosition - 1) + state.phoneNumber.slice(state.cursorPosition);
        state.cursorPosition = state.cursorPosition - 1;
      }
    },
    sipConnected: (state) => {
      state.sipConnected = true;
    },
    sipRegistered: (state) => {
      state.registered = true;
    },
    sipDisconnected: (state) => {
      state.callActive = false;
      state.sipConnected = false;
    },
    callConnecting: (state) => {
      state.callConnecting = true;
    },
    callInitiated: (state) => {
      state.callActive = true;
      state.callConnecting = false;
    },
    callConnected: (state, action) => {
      let callHistory = localStorage.getItem(CALL_HISTORY_KEY);
      
      if (callHistory === null) {
        callHistory = {};

        callHistory[action.payload.username] = [action.payload.callRecord];
      } else {
        callHistory = JSON.parse(callHistory);

        callHistory[action.payload.username].push(action.payload.callRecord);
      }

      localStorage.setItem(CALL_HISTORY_KEY, JSON.stringify(callHistory));

      state.callHistory = callHistory;
      state.callActive = true;
      state.callConnecting = false;
    },
    callDisconnected: (state, action) => {
      if (action.payload !== undefined) {
        let callHistory = localStorage.getItem(CALL_HISTORY_KEY);
      
        if (callHistory === null) {
          console.error(`Unable to update call duration for ${action.payload.number}`);
        } else {
          callHistory = JSON.parse(callHistory);
  
          if (callHistory.hasOwnProperty(action.payload.username)) {
            for (let i = callHistory[action.payload.username].length - 1; i >= 0; i--) {
              if (callHistory[action.payload.username][i].number === action.payload.number) {
                callHistory[action.payload.username][i].length = getCallDuration(state.callStart);
                break;
              }
            }
          }
        }
  
        localStorage.setItem(CALL_HISTORY_KEY, JSON.stringify(callHistory));
  
        state.callHistory = callHistory;
      }

      state.callActive = false;
      state.callStart = null;
      state.callConnecting = false;
    },
    answered: (state) => {
      state.callActive = true;
      state.callStart = DateTime.now().toMillis();
    },
    hold: (state) => {
      state.sipOnHold = true;
    },
    resume: (state) => {
      state.sipOnHold = false;
    },
    mute: (state) => {
      state.sipMuted = true;
    },
    unMute: (state) => {
      state.sipMuted = false;
    }
  },
});

export const {
  setPhoneNumber,
  setCursorPosition,
  removeDigit,
  sipConnected,
  sipRegistered,
  sipDisconnected,
  callInitiated,
  callConnected,
  callDisconnected,
  callConnecting,
  answered,
  hangUp,
  hold,
  resume,
  mute,
  unMute,
} = sipSlice.actions;

export default sipSlice.reducer;
