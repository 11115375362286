import { useRef, useEffect } from "react";
import { useXmppContext } from "services/XmppClient";
import ChatInput from "./ChatInput";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

const Message = ({ message, currentMessageTarget }) => {
  const timestamp = message.timestamp
    ? DateTime.fromISO(message.timestamp)
    : DateTime.utc();

  return (
    <div
      className={`chat-message${message.outgoing ? " outgoing" : " incoming"}`}
    >
      {!message.outgoing && currentMessageTarget && (
        <div className="message-data">{currentMessageTarget?.name}</div>
      )}
      {message.outgoing && <div className="message-data">You</div>}
      <p title={timestamp.toLocaleString(DateTime.DATETIME_FULL)}>
        {message.body}
        <span className="timestamp">
          {timestamp.toLocaleString(DateTime.TIME_WITH_SECONDS)}
        </span>
      </p>
    </div>
  );
};

const Chat = () => {
  const { sendMessage } = useXmppContext();
  const { messages, currentMessageTarget } = useSelector((state) => {
    const currentMessageTarget = state.xmpp.currentMessageTarget;
    let messages = [];
    
    if (currentMessageTarget && state.xmpp.messages[currentMessageTarget.jid]) {
      messages = state.xmpp.messages[currentMessageTarget.jid];
    }
    return {
      currentMessageTarget,
      messages,
    };
  });

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="header">
        <h3>Welcome to the Bullpen</h3>
        {/* <p>Viewing all messages from all contacts</p> */}
      </div>

      {currentMessageTarget && messages.length === 0 && (
        <div>
          <h3>No Message History for {currentMessageTarget.name}</h3>
        </div>
      )}

      {messages.length > 0 && (
        <div className="chat-list">
          {messages.map((message, i) => (
            <Message
              key={`message-${i}`}
              message={message}
              currentMessageTarget={currentMessageTarget}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      )}

      <ChatInput sendMessage={sendMessage} />
    </div>
  );
};

export default Chat;
