import { createContext, useContext } from "react";
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'

const MusicPlayerContext = createContext(null);

export const useMusicPlayerContext = () => {
    const ctx = useContext(MusicPlayerContext);

    if (!ctx) {
        throw new Error("MusicPlayerContext must be used within a MusicPlayerContext.Provider");
    }

    return ctx;
};

const STATION_URL = 'https://xmpp.pbxapi.com/music';

const options = {
    defaultPosition: { top: 0, left: '86vw' },
    remove: false,
    showDownload: false,
    showPlayMode: false,
    seeked: false,
    autoPlay: false,
    autoHiddenCover: true,
    audioLists: [
        {
            name: 'Hit List',
            musicSrc: `${STATION_URL}/002`,
            duration: 359999
        },
        {
            name: 'Pop Hits',
            musicSrc: `${STATION_URL}/033`,
            duration: 359999
        },
        {
            name: 'Soft Rock',
            musicSrc: `${STATION_URL}/001`,
            duration: 359999
        },
        {
            name: 'Classic Rock',
            musicSrc: `${STATION_URL}/006`,
            duration: 359999
        },
        {
            name: 'Today\'s Country',
            musicSrc: `${STATION_URL}/003`,
            duration: 359999
        }
    ],
}

const MusicPlayerProvider = ({ children }) => {
    let musicPlayerClient = null;
    let musicPlayer = null;

    const getMusicPlayer = () => {
        if (musicPlayer === null) {
            musicPlayer = <ReactJkMusicPlayer {...options} />;
        }

        return musicPlayer;
    }

    musicPlayerClient = {
        getMusicPlayer
    };

    return (
        <MusicPlayerContext.Provider value={musicPlayerClient}>{children}</MusicPlayerContext.Provider>
    );
};

export default MusicPlayerProvider;
