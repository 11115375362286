import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "./auth";
import { useMusicPlayerContext } from "./MusicPlayer";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuthContext();
  const { getMusicPlayer } = useMusicPlayerContext();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          return (
            <>
              <Component />
              {getMusicPlayer()}
            </>
          );
        }
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }}
    />
  );
};

export default ProtectedRoute;
