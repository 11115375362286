import { IconButton, Snackbar } from "@material-ui/core";
import { CallEnd, Mic, MicOff } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useSipContext } from "../services/SipClient";
import { useStopwatch } from "react-timer-hook";

const callLengthDisplay = (
  callLengthHours,
  callLengthMinutes,
  callLengthSeconds
) => {
  let displayString = "";

  if (callLengthHours > 0) {
    displayString += `${callLengthHours} hrs`;
  }

  if (callLengthMinutes > 0) {
    displayString += `${callLengthMinutes} mins`;
  }

  if (callLengthSeconds > 0) {
    displayString += `${callLengthSeconds} secs`;
  }

  return displayString;
};

const CallPresence = () => {
  const { callActive, sipMuted } = useSelector((state) => state.sip);
  const { toggleMute, endCall } = useSipContext();
  const { seconds, minutes, hours, isRunning, start, reset } = useStopwatch();

  if (!isRunning && callActive) {
    start();
  }

  if (isRunning && !callActive) {
    reset();
  }

  const callLength = callLengthDisplay(hours, minutes, seconds);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      message={`Call Active ${callLength}`}
      open={callActive}
      action={
        <>
          <IconButton title="End Call" color="inherit" onClick={endCall}>
            <CallEnd />
          </IconButton>
          <IconButton
            color="inherit"
            title={`${sipMuted ? "Unmute Microphone" : "Mute Microphone"}`}
            disabled={!callActive}
            onClick={() => toggleMute()}
          >
            {!sipMuted && <MicOff />}
            {sipMuted && <Mic />}
          </IconButton>
        </>
      }
    ></Snackbar>
  );
};

export default CallPresence;
