import { jid } from "@xmpp/client";
import { DateTime } from "luxon";

export const ParseMessage = (xmlMessage) => {
  const result = xmlMessage.getChild("result");
  let msg;
  let delay;
  let queryId;

  if (result) {
    const fwd = result.getChild("forwarded");
    queryId = result.attrs.queryid;
    delay = fwd.getChild("delay");
    msg = fwd.getChild("message");
  } else {
    msg = xmlMessage;
  }

  const fromJid = jid(msg.attrs.from);
  const toJid = jid(msg.attrs.to);
  const from = `${fromJid.local}@${fromJid.domain}`;
  const to = `${toJid.local}@${toJid.domain}`;

  // receive message
  return {
    from,
    to,
    body: msg.getChildText("body"),
    outgoing: queryId === to && true,
    timestamp: delay ? delay.attrs.stamp : DateTime.now().toISO(),
  };
};

export class MessageHistory {
  constructor(jid) {
    this.jid = jid;
    this.messages = [];
  }

  receivedMessage(message) {
    this.messages.push(message);
  }

  sentMessage(message) {
    // @TODO figure out how to mark messages as outgoing
    this.messages.push(message);
  }
}
