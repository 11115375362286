import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

const parseRosterName = (roster) => {
  const out = {
    rosterName: "",
    contacts: [],
  };
  
  const firstContact = roster.find((contact) => contact.groups.length === 1);

  out.rosterName = firstContact.groups[0];
  out.contacts = roster;

  return out;
};

const clearPresencePolling = (contacts) => {
  return contacts.map((c) => {
    clearTimeout(c.timeout);

    c.timeout = null;

    return c;
  });
}

export const xmppSlice = createSlice({
  name: "xmpp",
  initialState: {
    xmppConnected: false,
    userJid: null,
    userStatus: "online.png",
    userTimeout: null,
    currentMessageTarget: null,
    rosterName: "General",
    contacts: [],
    messages: {},
    composing: [],
  },
  reducers: {
    xmppConnected: (state, action) => {
      state.xmppConnected = true;
      state.userJid = action.payload;
    },
    xmppDisconnected: (state) => {
      state.xmppConnected = false;

      state.contacts = clearPresencePolling(state.contacts);

      clearTimeout(state.userTimeout);

      state.userTimeout = null;
    },
    rosterUpdated: (state, action) => {
      const { rosterName, contacts } = parseRosterName(action.payload);

      state.rosterName = rosterName;

      clearPresencePolling(state.contacts);

      state.contacts = contacts;
    },
    updateUserStatus: (state, action) => {
      state.userStatus = action.payload.status;

      clearTimeout(state.userTimeout);

      state.userTimeout = action.payload.timeout;
    },
    messageReceived: (state, action) => {
      const msg = action.payload;
      const conversationJid = msg.outgoing ? msg.to : msg.from;

      if (!state.messages[conversationJid]) {
        state.messages[conversationJid] = [];
      }

      state.messages[conversationJid] = [...state.messages[conversationJid], msg];

      if (state.currentMessageTarget && state.currentMessageTarget.jid === msg.from) {
        let messageHistory = JSON.parse(localStorage.getItem("messageHistory"));

        messageHistory[msg.from] = DateTime.now().toSeconds();

        localStorage.setItem("messageHistory", JSON.stringify(messageHistory));
      }
    },
    messageSent: (state, action) => {
      const msg = action.payload;

      if (!state.messages[msg.to]) {
        state.messages[msg.to] = [];
      }

      state.messages[msg.to] = [...state.messages[msg.to], msg];
    },
    userComposingMessage: (state, action) => {
      state.composing.push(action.payload);
    },
    currentMessageTargetUpdated: (state, action) => {
      state.currentMessageTarget = action.payload;
    },
    currentMessageTargetHistory: (state, action) => {
      state.currentMessageTargetHistory = action.payload;
    },
    updateContactStatus: (state, action) => {
      const contact = state.contacts.find((contact) => contact.jid === action.payload.jid);

      if (contact === undefined) {
        console.error(`Unable to locate contact info for ${action.payload.jid}`);
      } else {
        // Clear any existing polling
        clearTimeout(contact.timeout);

        contact.status = action.payload.status;
        contact.timeout = action.payload.timeout; 
      }
    },
  },
});

export const {
  xmppConnected,
  xmppDisconnected,
  rosterUpdated,
  updateUserStatus,
  currentMessageTargetUpdated,
  currentMessageTargetHistory,
  messageReceived,
  messageSent,
  userComposingMessage,
  updateContactStatus,
} = xmppSlice.actions;

export default xmppSlice.reducer;
