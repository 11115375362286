import React from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import SvgIcon from "@material-ui/core/SvgIcon";
import { CallMade, CallReceived } from "@material-ui/icons";
import FlatList from 'flatlist-react';
import { useAuthContext } from "services/auth";
import { TYPE_CALL_RECEIVED } from '../services/SipClient';

const CallLog = () => {
  const { username } = useAuthContext();
  const callLog = useSelector(state => state.sip.callHistory);

  let today = DateTime.utc().startOf("day");
  let callSegments = [];

  for (let i = 1; i <= 8; i++) {
    if (i <= 7) {
      if (i === 1) {
        callSegments.push({
          startTime: today.toMillis(),
          label: 'Today',
        });
      } else if (i === 2) {
        callSegments.push({
          startTime: today.minus({ days: 1 }).toMillis(),
          label: 'Yesterday',
        });
      } else {
        let startOfDay = today.minus({ days: i });

        callSegments.push({
          startTime: startOfDay.toMillis(),
          label: startOfDay.weekdayLong,
        });
      }
    } else {
      callSegments.push({
        startTime: 0,
        label: 'Older',
      });
    }
  }

  let calls = [];

  if (callLog.hasOwnProperty(username)) {
    let sortedCallLog = callLog[username].slice().sort((a, b) => b.time - a.time);

    for (const record of sortedCallLog) {
      for (const callSegment of callSegments) {
        if (record.time >= callSegment.startTime) {
          calls.push({ ...record, label: callSegment.label, startTime: callSegment.startTime });
          break;
        }
      }
    }
  }

  console.dir(calls);

  const renderCall = (call) => {
    const dateTime = DateTime.fromMillis(call.time);

    return (
      <li key={call.time} className="log-entry">
        <div className="inline-icon icon">
          <SvgIcon component={call.type === TYPE_CALL_RECEIVED
            ? CallReceived
            : CallMade}
          />
        </div>
        <div className="log-text">
          {call.contact && <div>{call.contact.name}</div>}
          <div className="number">{call.number}</div>
        </div>
        <div className="call-time">
          {call.label === 'Today'
            ? dateTime.toLocaleString(DateTime.TIME_WITH_SECONDS)
            : dateTime.toLocaleString(DateTime.DATE_MED)}
        </div>
      </li>
    );
  }

  const renderHeader = (group, index, groupLabel) => {
    return (
      <li key={groupLabel} className="log-entry">
        {groupLabel}
      </li>
    );
  }

  return (
    <div>
      <ul className="call-log">
        <h3>Call History</h3>
        <FlatList
          renderOnScroll
          list={calls}
          renderItem={(call) => renderCall(call)}
          groupSeparator={renderHeader}
          renderWhenEmpty={() => <div>You have not made any calls</div>}
          sortBy="startTime"
          sortDescending
          groupBy="label"
        />
      </ul>
    </div>
  );
};

export default CallLog;
